import { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import exportIcon from "./icons/export.svg";
import facebookIcon from "./icons/facebook.svg";
import linkedinIcon from "./icons/linkedin.svg";
import whatsappIcon from "./icons/whatsapp.svg";
import xIcon from "./icons/x.svg";
import headerBackground from "./images/headerBackground.png";
import guinnessLogo from "./icons/guinnessLogo.svg";
import guinnessMoto from "./icons/guinnessMoto.svg";
import { getImages } from "./api/images";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import { Pagination } from "@mantine/core";
import { track } from "./util";
import "./App.css";

function App() {
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [imagesToDisplay, setImagesToDisplay] = useState([]);

  const fetchImages = useCallback(async () => {
    const arr = await getImages();
    setImages(arr);

    setTotalPages(Math.ceil(arr.length / 24));

    const startIndex = (page - 1) * 24;
    const endIndex = startIndex + 24;
    setImagesToDisplay(arr.slice(startIndex, endIndex));
  }, [page]);

  const handlePageChange = useCallback(() => {
    const startIndex = (page - 1) * 24;
    const endIndex = startIndex + 24;
    setImagesToDisplay(images.slice(startIndex, endIndex));
    track("page");
  }, [page, images]);

  useEffect(() => {
    handlePageChange();
  }, [page, handlePageChange]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  return (
    <Container>
      <HeaderContainer>
        <HeaderBackround src={headerBackground} />
        <GuinnessLogoComponent src={guinnessLogo} alt="Guinness logo" />
        <GuinnessMotoComponent src={guinnessMoto} alt="Guinness moto" />
      </HeaderContainer>
      <MainContainer>
        {imagesToDisplay &&
          imagesToDisplay.map((image) => {
            return (
              <ItemContainer key={image.session_id}>
                <ImageComponent
                  src={`https://stpatricks.mobiusframe.com/uploads/app_blob/${image.session_id}.jpg`}
                  alt=""
                />
                <IconsContainer>
                  <a href={image.url + "?download=true"}>
                    <IconComponent
                      src={exportIcon}
                      alt="export icon"
                      onClick={() => track("download", image.session_id)}
                    />
                  </a>
                  <LinkedinShareButton
                    url={`https://stpatricks.mobiusframe.com/share/${image.session_id}`}
                  >
                    <IconComponent
                      src={linkedinIcon}
                      alt="linkedin icon"
                      onClick={() => track("linkedin", image.session_id)}
                    />
                  </LinkedinShareButton>
                  <WhatsappShareButton
                    url={`https://stpatricks.mobiusframe.com/share/${image.session_id}`}
                  >
                    <IconComponent
                      src={whatsappIcon}
                      alt="whatsapp icon"
                      onClick={() => track("whatsapp", image.session_id)}
                    />
                  </WhatsappShareButton>
                  <TwitterShareButton
                    url={`https://stpatricks.mobiusframe.com/share/${image.session_id}`}
                  >
                    <IconComponent
                      src={xIcon}
                      alt="x icon"
                      onClick={() => track("twitter", image.session_id)}
                    />
                  </TwitterShareButton>
                  <FacebookShareButton
                    url={`https://stpatricks.mobiusframe.com/share/${image.session_id}`}
                  >
                    <IconComponent
                      src={facebookIcon}
                      alt="facebook icon"
                      onClick={() => track("facebook", image.session_id)}
                    />
                  </FacebookShareButton>
                </IconsContainer>
              </ItemContainer>
            );
          })}
      </MainContainer>
      <PaginationContainer>
        <Pagination
          value={page}
          onChange={setPage}
          total={totalPages}
          color="#57BA58"
        />
      </PaginationContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const MainContainer = styled.div`
  box-sizing: border-box;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  grid-template-columns: repeat(6, 1fr);
  padding: 30px;
  display: grid;
  gap: 15px;

  @media (max-width: 1150px) {
    grid-template-columns: repeat(4, 1fr);
    padding: 30px;
    gap: 15px;
  }

  @media (max-width: 850px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 410px) {
    padding: 30px 10px;
  }

  @media (max-width: 380px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const IconsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 5px;

  @media (max-width: 850px) {
    margin-bottom: 30px;
  }
`;

const IconComponent = styled.img`
  width: 25px;
  height: 25px;
  cursor: pointer;

  @media (max-width: 850px) {
    width: 19px;
    height: 19px;
  }
`;

const ImageComponent = styled.img`
  width: 140px;
  height: 180px;

  @media (max-width: 600px) {
    width: 170px;
    height: 215px;
  }
`;

const HeaderContainer = styled.div`
  box-sizing: border-box;
  max-width: 1280px;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 135px;
  position: relative;
  margin: 0 auto;
`;

const HeaderBackround = styled.img`
  max-width: 1280px;
  width: 100%;
  object-fit: cover;
`;

const GuinnessLogoComponent = styled.img`
  max-width: 370px;
  width: 100%;
  max-height: 70px;
  height: 100%;
  position: absolute;
  left: 4%;
  bottom: -3%;

  @media (max-width: 650px) {
    max-width: 300px;
    width: 100%;
    max-height: 50px;
    height: 100%;
    bottom: -1%;
  }
`;

const GuinnessMotoComponent = styled.img`
  max-width: 175px;
  width: 100%;
  max-height: 45px;
  height: 100%;
  position: absolute;
  right: 4%;
  bottom: -3%;

  @media (max-width: 650px) {
    max-width: 130px;
    width: 100%;
    max-height: 30px;
    height: 100%;
    bottom: -1%;
  }

  @media (max-width: 500px) {
    max-width: 130px;
    width: 100%;
    max-height: 30px;
    height: 100%;
    top: 10%;
  }
`;

const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
`;

export default App;
